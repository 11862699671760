import "./comparison-baner.scss"

import React from "react"

import Button from "components/Button/Button"
import { useComparedProducts } from "context/compared_products"

const ComparisonBaner = () => {
  const {
    is_open_compared_product_banner,
    handleOpenComparedProductsBanner,
    compared_products,
  } = useComparedProducts()

  if (is_open_compared_product_banner && compared_products.length > 0) {
    return (
      <div className="comparison-baner" id="comparisonBaner">
        <div className="container-fluid">
          <div className="comparison-baner__wrapper">
            <h4>Przedmioty dodane do porównania:</h4>
            <div className="comparison-baner__row">
              {compared_products.map(({ image, name }, index) => (
                <div className="comparison-baner__item" key={index}>
                  {image && (
                    <div className="comparison-baner__item-image">
                      <img
                        className="img-fluid"
                        src={image?.localFile?.publicURL}
                        alt={name}
                      />
                    </div>
                  )}
                  <p className="comparison-baner__item-title">{name}</p>
                </div>
              ))}
            </div>
            <Button type="link" to="/porownywarka/">
              Porównanie
            </Button>
            <button
              className="comparison-baner__hide"
              type="button"
              onClick={() => handleOpenComparedProductsBanner(false)}
            >
              Zamknij
            </button>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default ComparisonBaner
