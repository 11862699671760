import { toast } from "react-toastify"
import React, { createContext, useContext, useState, useEffect } from "react"
import { useProducts } from "context/products"

const FavoriteProductsContext = createContext()

function FavoriteProductsProvider({ children }) {
  const { products } = useProducts()
  const [is_loaded, setLoaded] = useState(false)
  const [local_storage_favorites_products, setLocalStorageFavoriteProducts] =
    useState([])

  const favorite_products = products.filter(({ id }) =>
    local_storage_favorites_products.includes(id)
  )

  useEffect(() => {
    setLocalStorageFavoriteProducts(
      JSON.parse(localStorage.getItem("favoriteProducts")) || []
    )
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (is_loaded) {
      localStorage.setItem(
        "favoriteProducts",
        JSON.stringify(local_storage_favorites_products)
      )
    }
  }, [local_storage_favorites_products]) //eslint-disable-line

  const setProductToFavorites = id => {
    const products = [...local_storage_favorites_products]
    const exists = products.includes(id)
    if (exists) {
      setLocalStorageFavoriteProducts(
        products.filter(product => product !== id)
      )
      toast.warning("Produkt został usunięty z ulubionych.")
    } else {
      setLocalStorageFavoriteProducts([...products, id])
      toast.success("Produkt został dodany do ulubionych.")
    }
  }

  return (
    <FavoriteProductsContext.Provider
      value={{
        favorite_products,
        local_storage_favorites_products,
        setProductToFavorites,
      }}
    >
      {children}
    </FavoriteProductsContext.Provider>
  )
}

const useFavoriteProducts = () => useContext(FavoriteProductsContext)
export { FavoriteProductsContext, useFavoriteProducts }
export default FavoriteProductsProvider
