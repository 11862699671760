import "./header-cart.scss"

import React from "react"
import NumberFormat from "react-number-format"

import Button from "components/Button/Button"

const HeaderCart = ({ cart }) => {
  const totalParsed = parseInt(
    cart.totalSubProductsPrice.match(/\d|\./g).join("")
  )

  return (
    <div className="header__cart">
      <div className="header__cart-inner">
        {cart &&
          cart.products.map((item, index) => (
            <div className="header__cart-item" key={index}>
              <div className="row">
                <div className="col">
                  <div className="header__cart-item-image">
                    {item.image && (
                      <img src={item.image.sourceUrl} alt={item.name} />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <p>{item.name}</p>
                </div>
                <div className="col">
                  <p>{item.qty} szt.</p>
                </div>
                <div className="col">
                  <p>{item.totalPrice}</p>
                </div>
              </div>
            </div>
          ))}

        <div className="header__cart-shipping-info">
          <p>
            <img src={require("assets/images/ico-box.svg").default} alt="" />
            {totalParsed < 2000 ? (
              <>
                Do darmowej wysyłki brakuje Ci{" "}
                <NumberFormat
                  value={2000 - totalParsed}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  suffix=".00zł"
                />
              </>
            ) : (
              "Osiągnąłeś próg darmowej wysyłki!"
            )}
          </p>
        </div>

        <div className="header__cart-cta">
          <div>
            <p>
              Kwota do zapłaty:{" "}
              <strong>{cart && cart.totalSubProductsPrice}</strong>
            </p>
          </div>
          <Button type="link" to="/koszyk/">
            Zobacz koszyk
          </Button>
        </div>
      </div>
    </div>
  )
}

export default HeaderCart
