import React from "react"
import { Link } from "gatsby"

import { useComparedProducts } from "context/compared_products"

const ComparisonList = () => {
  const { compared_products, removeProductFromCompare } = useComparedProducts()

  if (compared_products.length > 0) {
    return compared_products.map((item, index) => (
      <div className="col-lg-3 col-4 col-bordered" key={index}>
        <div className="comparison__item">
          <div className="comparison__item-header">
            <Link to={`/${item.slug}`}>{item.name}</Link>
            <button onClick={() => removeProductFromCompare(item.id)}>
              Close
            </button>
          </div>
          <div className="comparison__item-image">
            {item.image && (
              <img
                className="img-fluid"
                src={item.image?.localFile?.publicURL}
                alt={item.name}
              />
            )}
          </div>
          <span className="comparison__item-mobile-label">
            Podstawowe porównanie
          </span>
          <ul className="comparison__list">
            <li>{item.acfProduct.procesorModel || "-"}</li>
            <li>
              {item.acfProduct.pamiecRamIlosc || "-"}{" "}
              {item.acfProduct.pamiecRamRodzajPamieci || "-"}
            </li>
            <li>
              {item.acfProduct.dyskTwardyWielkosc || "-"}{" "}
              {item.acfProduct.dyskTwardyRodzaj || "-"}
            </li>
            <li>
              {item.acfProduct.matrycaRozdzielczosc || "-"},{" "}
              {item.acfProduct.matrycaRodzaj || "-"}
            </li>
            <li>
              Klasa{" "}
              {item.paKlasaProduktus.nodes.map((item, index) => (
                <span key={index}>{item.name}</span>
              ))}
            </li>
            <li>{item.price ? item.price : "-"}</li>
          </ul>
          <div className="comparison__divider"></div>
          <span className="comparison__item-mobile-label">Procesor</span>
          <ul className="comparison__list">
            <li>{item.acfProduct.procesorLiczbaRdzeni || "-"}</li>
            <li>{item.acfProduct.procesorLiczbaWatkow || "-"}</li>
            <li>{item.acfProduct.procesorBazoweTaktowanie || "-"}</li>
            <li>{item.acfProduct.procesorTurboTaktowanie || "-"}</li>
          </ul>
          <span className="comparison__item-mobile-label">Pamięć RAM</span>
          <ul className="comparison__list">
            <li className="divider"></li>
            <li>{item.acfProduct.pamiecRamIlosc || "-"}</li>
            <li>{item.acfProduct.pamiecRamRodzajPamieci || "-"}</li>
            <li>{item.acfProduct.pamiecRamTaktowanie || "-"}</li>
          </ul>
          <span className="comparison__item-mobile-label">Dysk</span>
          <ul className="comparison__list">
            <li className="divider"></li>
            <li> {item.acfProduct.dyskTwardyRodzaj || "-"}</li>
            <li> {item.acfProduct.dyskTwardyWielkosc || "-"}</li>
          </ul>
        </div>
      </div>
    ))
  }
  return (
    <div className="col-md-9">
      <div className="row__products-divider"></div>
      <p className="row__products-empty-info">
        Brak produktów w <strong>porównywarce</strong>.
      </p>
    </div>
  )
}

export default ComparisonList
