import "./header-search.scss"

import React, { useState, useEffect } from "react"
import { Field, Formik } from "formik"
import { useQueryParam, StringParam } from "use-query-params"
import { navigate } from "gatsby"

import Button from "../../Button/Button"

const HeaderSearch = ({ searchBaner, allWpProductPrivate }) => {
  const [mainTitle, setMainTitle] = useQueryParam("mainTitle", StringParam)
  const [filled, setFilled] = useState(false)

  const onSubmit = values => {
    setMainTitle(values.mainTitle)

    values.mainTitle === process.env.GATSBY_PRIVATE_PRODUCTS_PASS &&
      localStorage.setItem("memtech-private-archive", values.mainTitle)

    values.mainTitle === process.env.GATSBY_PRIVATE_PRODUCTS_PASS
      ? navigate("/produkty-prywatne/")
      : navigate(`/produkty/` + document.location.search)
  }

  useEffect(() => {
    allWpProductPrivate.filter(
      item =>
        mainTitle === item?.acfProduct?.privateCategoryPassword &&
        navigate(`/${item.slug}/`)
    )
  })

  const inputBlur = ({ target: { value } }) => {
    value.length > 0 ? setFilled(true) : setFilled(false)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        mainTitle,
      }}
    >
      {({ handleSubmit }) =>
        searchBaner && (
          <div className="header__search">
            <form className="header__search-wrapper" onSubmit={handleSubmit}>
              <div className="header__search-input">
                <Field
                  type="text"
                  name="mainTitle"
                  placeholder="Czego szukasz?"
                  onKeyUp={inputBlur}
                />
              </div>
              <div className="header__search-button">
                <Button type="submit" className={!filled ? "button--gray" : ""}>
                  Szukaj
                </Button>
              </div>
            </form>
          </div>
        )
      }
    </Formik>
  )
}

export default HeaderSearch
