import React, { createContext, useContext } from "react"

const ProductsContext = createContext()

function ProductsProvider({ children, value }) {
  return (
    <ProductsContext.Provider value={{ products: value }}>
      {children}
    </ProductsContext.Provider>
  )
}

const useProducts = () => useContext(ProductsContext)
export { ProductsContext, useProducts }
export default ProductsProvider
