import "./cookie-baner.scss"

import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { Link } from "gatsby"

const CookieBaner = ({ cookies }) => {
  const [cookieBaner, setCookieBaner] = useState("")

  useEffect(() => {
    const isAcceptedCoookie = !!cookies.get("cookie-accept-memtech")
    !isAcceptedCoookie && setCookieBaner(true)
  }, [cookies])

  const acceptCookieBaner = () => {
    const promiseSetCookie = new Promise(resolve =>
      resolve(cookies.set("cookie-accept-memtech", "active", { path: "/" }))
    )
    promiseSetCookie.then(() => {
      setCookieBaner(false)
    })
  }

  return (
    <div className={`cookie-baner${cookieBaner ? " open" : ""}`}>
      <div className="container-fluid">
        <div className="cookie-baner__wrapper">
          <p>
            Aby zoptymalizować naszą stronę internetową i stale ją ulepszać,
            używamy <span>plików cookie</span>.
          </p>
          <div className="cookie-baner__cta">
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                acceptCookieBaner()
              }}
            >
              Akceptuję
            </a>
            <Link to="/polityka-cookies/">Więcej informacji</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withCookies(CookieBaner)
