import "./nav.scss"

import React from "react"
import { Link } from "gatsby"

import IcoLaptop from "assets/images/ico-laptop.svg"
import IcoMouse from "assets/images/ico-mouse.svg"
import IcoBox from "assets/images/ico-box.svg"
import IcoTools from "assets/images/ico-tools.svg"

const Nav = ({ isMenuOpen }) => {
  return (
    <nav className={`nav${isMenuOpen ? " nav--open" : ""}`}>
      <div className="container-fluid">
        <div className="nav__wrapper">
          <div className="nav__item nav__item--submenu">
            <Link
              to="/laptopy/"
              className="nav__item nav__item--main"
              activeClassName="current"
            >
              <img src={IcoLaptop} alt="" />
              Laptopy
            </Link>
            <div className="nav__submenu">
              <Link
                to="/apple/"
                className="nav__item"
                activeClassName="current"
              >
                Apple
              </Link>
              <Link to="/dell/" className="nav__item" activeClassName="current">
                Dell
              </Link>
              <Link
                to="/fujitsu/"
                className="nav__item"
                activeClassName="current"
              >
                Fujitsu
              </Link>
              <Link
                to="/hp-compaq/"
                className="nav__item"
                activeClassName="current"
              >
                HP, Compaq
              </Link>
              <Link
                to="/ibm-lenovo/"
                className="nav__item"
                activeClassName="current"
              >
                IBM, Lenovo
              </Link>
              <Link to="/laptopy/" className="nav__info">
                Zobacz wszystkie
              </Link>
            </div>
          </div>

          <span className="nav__divider"></span>

          <div className="nav__item nav__item--submenu">
            <Link
              to="/urzadzenia-peryferyjne/"
              className="nav__item nav__item--main"
              activeClassName="current"
            >
              <img src={IcoMouse} alt="" />
              Urządzenia peryferyjne
            </Link>
            <div className="nav__submenu">
              <Link
                to="/myszki/"
                className="nav__item"
                activeClassName="current"
              >
                Myszki
              </Link>
              <Link
                to="/klawiatury/"
                className="nav__item"
                activeClassName="current"
              >
                Klawiatury
              </Link>
              <Link
                to="/kamery-internetowe/"
                className="nav__item"
                activeClassName="current"
              >
                Kamery internetowe
              </Link>
              <Link to="/urzadzenia-peryferyjne/" className="nav__info">
                Zobacz wszystkie
              </Link>
            </div>
          </div>

          <span className="nav__divider"></span>

          <Link to="/zakupy-hurtowe/" className="nav__item nav__item--main">
            <img src={IcoBox} alt="" />
            Zakupy hurtowe
          </Link>

          <span className="nav__divider"></span>

          <Link to="/serwis/" className="nav__item nav__item--main">
            <img src={IcoTools} alt="" />
            Serwis
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Nav
