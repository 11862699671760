import "./page-header.scss"

import React from "react"

const PageHeader = ({ title, className }) => {
  return (
    <h1 className={`page__header ${className ? className : ""}`}>{title}</h1>
  )
}

export default PageHeader
