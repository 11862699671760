import "./footer.scss"

import React, { useState } from "react"
import { Link } from "gatsby"

import IcoPrzelewy24 from "assets/images/logo-przelewy24.svg"
import IcoPocztaPolska from "assets/images/logo-poczta.svg"
import IcoInPost from "assets/images/logo-inpost.svg"
import IcoFacebook from "assets/images/ico-facebook.svg"

const Footer = () => {
  const isBrowser = typeof window !== "undefined"
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (isBrowser && !showScroll && window.pageYOffset > 100) {
      setShowScroll(true)
    } else if (isBrowser && showScroll && window.pageYOffset <= 100) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    isBrowser && window.scrollTo({ top: 0, behavior: "smooth" })
  }
  isBrowser && window.addEventListener("scroll", checkScrollTop)

  return (
    <footer className="footer">
      <button
        onClick={scrollTop}
        className="footer__scroll-top"
        style={{ opacity: showScroll ? 1 : 0 }}
      >
        Scroll to top
      </button>

      <div className="container-fluid">
        <div className="footer__wrapper">
          <div className="row">
            <div className="col-xl-2 col-md-4 col-sm-6">
              <h4>Memtech</h4>
              <address className="footer__address">
                Doroszewskiego 7 <br />
                01-318 Warszawa <br />
                NIP: 726-265-53-49
              </address>
              <address className="footer__address">
                Telefon: <br />
                <a href="tel:+48511505050">511-505-050</a> <br />
                <small>(pon. - pt. 10:00 - 18:00)</small>
              </address>
              <address className="footer__address">
                Email: <br />
                <a href="mailto:kontakt@memtech.pl">kontakt@memtech.pl</a>
              </address>
            </div>

            <div className="col-xl-2 col-md-4 col-sm-6">
              <h4>Oferta</h4>
              <div className="footer__list">
                <Link to="/laptopy/">Laptopy</Link>
                <Link to="/urzadzenia-peryferyjne/">
                  Urządzenia peryferyjne
                </Link>
                <Link to="/zakupy-hurtowe/">Zakupy hurtowe</Link>
                <Link to="/serwis/">Serwis</Link>
                <Link to="/ulubione/">Ulubione</Link>
                <Link to="/porownywarka/">Porównywarka</Link>
              </div>
            </div>

            <div className="col-xl-2 col-md-4 col-sm-6">
              <h4>Płatności i dostawa</h4>
              <div className="footer__list">
                <Link to="/formy-platnosci/">Formy płatności</Link>
                <Link to="/czas-i-koszty-dostawy/">Czas i koszty dostawy</Link>
                <Link to="/czas-realizacji-zamowienia/">
                  Czas realizacji zamówienia
                </Link>
                <Link to="/zwroty-i-reklamacje/">Zwroty i reklamacje</Link>
              </div>
            </div>

            <div className="col-xl-2 col-md-4 col-sm-6">
              <h4>Moje konto</h4>
              <div className="footer__list">
                <Link to="/moje-konto/">Moje zamówienia</Link>
                <Link to="/moje-konto/">Ustawienia konta</Link>
              </div>
            </div>

            <div className="col-xl-2 col-md-4 col-sm-6">
              <h4>O nas</h4>
              <div className="footer__list">
                <Link to="/kontakt/">Kontakt</Link>
                <Link to="/o-firmie/">O firmie</Link>
                <Link to="/regulamin/">Regulamin</Link>
                <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>
                <Link to="/polityka-cookies/">Polityka cookies</Link>
              </div>
            </div>

            <div className="col-xl-2 col-md-4 col-sm-6">
              <h4>Dołącz do nas</h4>
              <a
                className="footer__link"
                href="https://www.facebook.com/memtechpl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={IcoFacebook} alt="" />
                Facebook
              </a>
            </div>
          </div>
        </div>

        <div className="footer__copy">
          <small>Copyright © 2021 Memtech</small>
          <div className="footer__shipping">
            <img src={IcoPrzelewy24} alt="" />
            <img src={IcoInPost} alt="" />
            <img src={IcoPocztaPolska} alt="" />
          </div>
          <small>
            Projekt i wykonanie:{" "}
            <a href="https://adream.pl/" target="_blank" rel="noreferrer">
              ADream
            </a>
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
