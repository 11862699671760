import "./checkbox.scss"

import React from "react"

const Checkbox = ({ children, name, checked, onChange, required }) => {
  return (
    <div className="checkbox">
      <label className="checkbox__label">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          value={checked}
          onChange={onChange}
          required={required}
        />
        <span>
          {children}
          {required && <sup>*</sup>}
        </span>
      </label>
    </div>
  )
}

export default Checkbox
