import "./page-sub-header.scss"

import React from "react"

const PageSubHeader = ({ title, className }) => {
  return (
    <h4 className={`page__sub-header ${className ? className : ""}`}>
      {title}
    </h4>
  )
}

export default PageSubHeader
