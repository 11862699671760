import React, { useState } from "react"
import { CookiesProvider, withCookies } from "react-cookie"
import { toast } from "react-toastify"
import { StaticQuery, graphql } from "gatsby"
import ProductsProvider from "context/products"
import ComparedProductsProvider from "context/compared_products"
import FavoriteProductsProvider from "context/favorite_products"
import Header from "components/Header/Header"
import Nav from "components/Nav/Nav"
import Newsletter from "./Newsletter/Newsletter"
import Footer from "components/Footer/Footer"
import CookieBaner from "components/CookieBaner/CookieBaner"
import { ComparisonBaner } from "components/Comparison"

import "styles/app.scss"

const Layout = ({ children, rmNewsletter, newFavLength }) => {
  toast.configure()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const layoutData = {
    isMenuOpen,
    setIsMenuOpen,
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allWpProduct(
            filter: {
              productCategories: {
                nodes: { elemMatch: { slug: { nin: "private-category" } } }
              }
            }
          ) {
            nodes {
              id
              databaseId
              name
              slug

              image {
                sourceUrl
                localFile {
                  publicURL
                }
              }

              ... on WpSimpleProduct {
                price
                stockQuantity
              }

              paKlasaProduktus {
                nodes {
                  name
                }
              }
              paProducents {
                nodes {
                  name
                }
              }
              productCategories {
                nodes {
                  name
                }
              }
              acfProduct {
                procesorModel
                procesorLiczbaRdzeni
                procesorLiczbaWatkow
                procesorBazoweTaktowanie
                procesorTurboTaktowanie
                pamiecRamIlosc
                pamiecRamRodzajPamieci
                pamiecRamTaktowanie
                dyskTwardyRodzaj
                dyskTwardyWielkosc
                matrycaRozdzielczosc
                matrycaTyp
                matrycaRodzaj
              }
            }
          }
          allWpProductPrivate: allWpProduct(
            filter: {
              productCategories: {
                nodes: { elemMatch: { slug: { in: "private-category" } } }
              }
            }
          ) {
            nodes {
              id
              databaseId
              name
              slug
              acfProduct {
                privateCategoryPassword
              }
            }
          }
        }
      `}
      render={data => (
        <CookiesProvider>
          <ProductsProvider value={data.allWpProduct.nodes}>
            <ComparedProductsProvider>
              <FavoriteProductsProvider>
                <Header
                  {...layoutData}
                  allWpProductPrivate={data.allWpProductPrivate.nodes}
                />
                <Nav {...layoutData} />
                <main>{children}</main>
                {!rmNewsletter && <Newsletter />}
                <Footer />
                <CookieBaner />
                <ComparisonBaner />
              </FavoriteProductsProvider>
            </ComparedProductsProvider>
          </ProductsProvider>
        </CookiesProvider>
      )}
    />
  )
}

export default withCookies(Layout)
