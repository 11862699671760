import { toast } from "react-toastify"
import React, { createContext, useContext, useState, useEffect } from "react"
import { useProducts } from "context/products"

const ComparedProductsContext = createContext()

function ComparedProductsProvider({ children }) {
  const { products } = useProducts()
  const [is_loaded, setLoaded] = useState(false)
  const [is_open_compared_product_banner, handleOpenComparedProductsBanner] =
    useState(false)
  const [local_storage_compared_products, setLocalStorageComparedProducts] =
    useState([])

  const compared_products = products.filter(({ id }) =>
    local_storage_compared_products.includes(id)
  )

  useEffect(() => {
    setLocalStorageComparedProducts(
      JSON.parse(localStorage.getItem("compareProducts")) || []
    )
    handleOpenComparedProductsBanner(
      JSON.parse(localStorage.getItem("compareBaner")) || false
    )
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (is_loaded) {
      localStorage.setItem("compareBaner", is_open_compared_product_banner)
    }
  }, [is_open_compared_product_banner]) //eslint-disable-line

  useEffect(() => {
    if (is_loaded) {
      localStorage.setItem(
        "compareProducts",
        JSON.stringify(local_storage_compared_products)
      )
    }
  }, [local_storage_compared_products]) //eslint-disable-line

  const setProductToCompare = id => {
    const products = [...local_storage_compared_products]
    const exists = products.includes(id)
    if (exists) {
      setLocalStorageComparedProducts(
        products.filter(product => product !== id)
      )
      toast.warning("Produkt został usunięty z porównywarki.")
    } else {
      if (products.length >= 3) {
        toast.error("Maksymalna ilość produktów w porównywarce: 3.")
      } else {
        setLocalStorageComparedProducts([...products, id])
        handleOpenComparedProductsBanner(true)
        toast.success("Produkt został dodany do porównywarki.")
      }
    }
  }

  const removeProductFromCompare = id => {
    const products = [...local_storage_compared_products]
    setLocalStorageComparedProducts(products.filter(product => product !== id))
    toast.warning("Produkt został usunięty z porównywarki.")
  }

  useEffect(() => {
    const products_to_remove = []
    local_storage_compared_products.forEach(item => {
      const exists = products.find(({ id }) => id === item)
      if (!exists) {
        products_to_remove.push(item)
      }
    })

    if (products_to_remove?.length > 0) {
      products_to_remove.forEach(id => removeProductFromCompare(id))
    }
  }, [local_storage_compared_products, products]) //eslint-disable-line

  return (
    <ComparedProductsContext.Provider
      value={{
        is_open_compared_product_banner,
        handleOpenComparedProductsBanner,
        compared_products,
        local_storage_compared_products,
        setProductToCompare,
        removeProductFromCompare,
      }}
    >
      {children}
    </ComparedProductsContext.Provider>
  )
}

const useComparedProducts = () => useContext(ComparedProductsContext)
export { ComparedProductsContext, useComparedProducts }
export default ComparedProductsProvider
