import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"

import ComparisonIcoMain from "assets/images/comparison-ico-main.svg"

const ComparisonNav = () => {
  return (
    <div className="comparison__nav">
      <div className="comparison__nav-top">
        <img src={ComparisonIcoMain} alt="" />
        <p>
          Sprawdź, który z produktów
          <br /> będzie idealny właśnie dla Ciebie!
        </p>
      </div>
      <div className="comparison__nav-item">
        <PageSubHeader title="Podstawowe porównanie" />
        <ul className="comparison__list">
          <li>Procesor:</li>
          <li>Pamięć RAM:</li>
          <li>Dysk:</li>
          <li>Matryca:</li>
          <li>Stan wizualny:</li>
          <li>Cena:</li>
        </ul>
      </div>

      <div className="comparison__nav-item">
        <PageSubHeader title="Szczegółowe porównanie" />
        <ul className="comparison__list">
          <li className="first">
            <strong>Procesor</strong>
          </li>
          <ul className="comparison__list">
            <li>Liczba rdzeni</li>
            <li>Liczba wątków</li>
            <li>Bazowe taktowanie</li>
            <li>Taktowanie w trybie Turbo</li>
          </ul>
          <li className="first">
            <strong>Pamięć RAM</strong>
          </li>
          <ul className="comparison__list">
            <li>Ilość</li>
            <li>Rodzaj</li>
            <li>Taktowanie</li>
          </ul>
          <li className="first">
            <strong>Dysk</strong>
          </li>
          <ul className="comparison__list">
            <li>Rodzaj pamięci</li>
            <li>Wielkość</li>
          </ul>
        </ul>
      </div>
    </div>
  )
}

export default ComparisonNav
