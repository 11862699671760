import "./button.scss"

import React from "react"
import { Link } from "gatsby"

const Button = ({ type, children, className, disabled, onClick, to }) => {
  return type === "link" ? (
    <Link
      to={to}
      className={`button ${className ? className : ""}${
        disabled ? " disabled" : ""
      }`}
      disabled={disabled}
    >
      <span>{children}</span>
    </Link>
  ) : (
    <button
      className={`button ${className ? className : ""}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  )
}

export default Button
