/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "./header.scss"
import React, { useState, useEffect, useContext } from "react"
import { useFavoriteProducts } from "context/favorite_products"
import { Link, navigate } from "gatsby"

import Button from "components/Button/Button"
import { isUserLoggedIn } from "utils/functions"
import { AppContext } from "components/Context/AppContext"
import IconHeart from "components/Icons/IconHeart"

import IcoHome from "assets/images/ico-home.svg"
import IcoHeadphones from "assets/images/ico-headphones.svg"
import IcoUser from "assets/images/ico-user.svg"
import IcoCart from "assets/images/ico-cart.svg"
import IcoSearch from "assets/images/ico-search.svg"
import HeaderSearch from "./HeaderSearch/HeaderSearch"
import HeaderCart from "./HeaderCart/HeaderCart"

const Header = ({ isMenuOpen, setIsMenuOpen, allWpProductPrivate }) => {
  const isBrowser = typeof window !== "undefined"

  const [isScrolled, setIsScrolled] = useState(false)
  const [searchBaner, setSearchBaner] = useState(null)
  const auth = isUserLoggedIn()
  const [cart] = useContext(AppContext)
  const { local_storage_favorites_products } = useFavoriteProducts()

  if (typeof window !== "undefined") {
    if (isMenuOpen === true) {
      document.body.classList.add("appleFix")
    } else {
      document.body.classList.remove("appleFix")
    }
  }

  const productsCount =
    null !== cart && Object.keys(cart).length ? cart.totalProductsCount : ""

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 10)
    })

    if (isBrowser && window.innerWidth < 991) {
      setSearchBaner(false)
    } else {
      setSearchBaner(true)
    }
  }, [isBrowser])

  const headerData = {
    searchBaner,
    setSearchBaner,
  }

  return (
    <header
      className={`header${isScrolled ? " header--shrink" : ""}`}
      id="header"
    >
      <div className="container-fluid">
        <div className="header__wrapper">
          <Link to="/" className="header__logo">
            <img src={require("assets/images/logo.svg").default} alt="" />
          </Link>

          <HeaderSearch
            {...headerData}
            allWpProductPrivate={allWpProductPrivate}
          />

          <div className="header__cta">
            <button
              onClick={() => setSearchBaner(!searchBaner)}
              className="header__cta-item header__cta--search"
            >
              <img src={IcoSearch} alt="" />
            </button>
            <Link
              to="/o-firmie/"
              className="header__cta-item header__cta--about"
            >
              <img src={IcoHome} alt="" /> O firmie
            </Link>
            <Link to="/kontakt/" className="header__cta-item">
              <img src={IcoHeadphones} alt="" /> Kontakt
            </Link>
            {auth ? (
              <Link to="/moje-konto/" className="header__cta-item">
                <img src={IcoUser} alt="" /> Moje konto
              </Link>
            ) : (
              <div className="header__cta-item">
                <img src={IcoUser} alt="" /> Moje konto
                <div className="header__submenu">
                  <div className="header__submenu-inner">
                    <Button type="link" to="/logowanie/">
                      Zaloguj się
                    </Button>
                    <p>Nie masz konta?</p>
                    <Button
                      type="link"
                      to="/rejestracja/"
                      className="button--bordered"
                    >
                      Zarejestruj się
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <Link
              to="/ulubione/"
              className={`header__cta-item header__cta--wishlist${
                local_storage_favorites_products.length > 0 ? " filled" : ""
              }`}
            >
              <IconHeart />
              {local_storage_favorites_products.length > 0 && (
                <span className="header__cta--wishlist-amount">
                  {local_storage_favorites_products.length}
                </span>
              )}
            </Link>
            <div
              className="header__cta-item header__cta--cart"
              onClick={() => navigate("/koszyk/")}
            >
              <img src={IcoCart} alt="" /> Koszyk{" "}
              <span className="header__cta--cart-amount">
                {productsCount ? productsCount : "0"}
              </span>
              {cart && <HeaderCart cart={cart} />}
            </div>
            <button
              aria-label="Open Menu"
              className={`hamburger hamburger--squeeze header__mobile${
                isMenuOpen ? " is-active" : ""
              }`}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {Array(4)
                .fill(null)
                .map((item, index) => (
                  <span key={index} />
                ))}
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
