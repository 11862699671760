import "./newsletter.scss"

import React, { useState, useEffect } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { toast } from "react-toastify"

import Button from "components/Button/Button"
import Checkbox from "components/Checkbox/Checkbox"

const Newsletter = () => {
  const [legal, setLegal] = useState(false)
  const [newsletterParam] = useQueryParam("newsletter", StringParam)

  useEffect(() => {
    newsletterParam === "success" &&
      toast.success("Pomyślnie zapisano na newsletter!")
  }, [newsletterParam])

  return (
    <section className="newsletter">
      <div className="container-fluid">
        <div className="newsletter__wrapper">
          <div className="row">
            <div className="col-md-3 offset-lg-1">
              <h3>
                Zapisz się do naszego <strong>Newslettera</strong>
              </h3>
              <p>
                Dowiedź się pierwszy o najnowszych promocjach oraz wydarzeniach
                w naszym sklepie!
              </p>
            </div>
            <div className="col-md-4 offset-xl-1">
              <form
                method="post"
                action="https://app.freshmail.com/pl/actions/subscribe/"
              >
                <input
                  type="hidden"
                  name="subscribers_list_hash"
                  value="5l6c1k16b8"
                />
                <input
                  type="text"
                  id="freshmail_email"
                  name="freshmail_email"
                  placeholder="Podaj swój adres email"
                  required
                />
                <div className="row">
                  <div className="col-xl-6">
                    <Checkbox value={legal} onChange={() => setLegal(!legal)}>
                      Chce otrzymywać informacje od firmy MEMTECH Sp. z o.o. Sp.
                      k. i akceptuję{" "}
                      <a href="/polityka-prywatnosci/" target="_blank">
                        politykę prywatności
                      </a>
                      .
                    </Checkbox>
                  </div>
                  <div className="col-xl-6 text-right">
                    <Button type="submit" disabled={!legal}>
                      Zapisz się
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
