import React from "react"

const IconHeart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.363"
      height="25.543"
      viewBox="0 0 28.363 25.543"
    >
      <g
        id="Component_58_1"
        data-name="Component 58 – 1"
        transform="translate(0.327 0.257)"
      >
        <g id="Group_323" data-name="Group 323">
          <path
            id="Path_494"
            data-name="Path 494"
            d="M405.75,1976.176a.482.482,0,0,1-.3-.106c-.464-.372-11.385-9.181-13.019-14.395a8.837,8.837,0,0,1,.637-7.412,6.3,6.3,0,0,1,4.787-3.06,8.834,8.834,0,0,1,7.9,3.5,8.844,8.844,0,0,1,7.9-3.5,6.3,6.3,0,0,1,4.787,3.06,8.846,8.846,0,0,1,.629,7.438c-1.627,5.188-12.548,14-13.012,14.369A.478.478,0,0,1,405.75,1976.176Zm-6.917-24.057a7.1,7.1,0,0,0-.865.052,5.355,5.355,0,0,0-4.073,2.6,7.949,7.949,0,0,0-.548,6.588c1.408,4.493,10.6,12.222,12.4,13.7,1.8-1.482,10.995-9.208,12.4-13.678a7.965,7.965,0,0,0-.541-6.614,5.352,5.352,0,0,0-4.073-2.6c-4.655-.566-7.343,3.576-7.37,3.617a.489.489,0,0,1-.412.225h0a.485.485,0,0,1-.41-.225C405.315,1955.749,402.944,1952.119,398.833,1952.119Z"
            transform="translate(-391.895 -1951.14)"
            fill="#e72e7c"
            stroke="#e72e7c"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  )
}

export default IconHeart
