import { isEmpty } from "lodash"
import DOMPurify from "dompurify"
import { v4 } from "uuid"
import { withPrefix } from "gatsby"

const isBrowser = typeof window !== "undefined"

//
export const isUserLoggedIn = () => {
  let isLoggedIn = false

  if (isBrowser) {
    isLoggedIn = !!localStorage.getItem("authToken")
  }
  return isLoggedIn
}

//
export const logOut = () => {
  localStorage.removeItem("authToken")
}

//
export const setAuth = ({ authToken }) => {
  localStorage.setItem("authToken", authToken)
}

//
export const isUserValidated = () => {
  let userLoggedInData = ""

  if (isBrowser) {
    let authTokenData = localStorage.getItem("authToken")

    if (!isEmpty(authTokenData)) {
      if (!isEmpty(authTokenData.authToken)) {
        userLoggedInData = authTokenData
      }
    }
  }

  return userLoggedInData
}

// Sanitize markup or text when used inside dangerouslysetInnerHTML
export const sanitize = content => {
  return isBrowser ? DOMPurify.sanitize(content) : content
}

// Handle last viewed products
export const handleViewedProducts = (
  id,
  viewedProducts,
  setViewedProducts,
  fetchViewedProducts
) => {
  setViewedProducts(viewedProducts => {
    viewedProducts.push(id)
    localStorage.setItem("viewedProducts", JSON.stringify(viewedProducts))
    fetchViewedProducts()
  })
}

// Extracts and returns float value from a string.
export const getFloatVal = string => {
  let floatValue = string.match(/[+-]?\d+(\.\d+)?/g)[0]
  return null !== floatValue
    ? parseFloat(parseFloat(floatValue).toFixed(2))
    : ""
}

// Returns cart data in the required format.
export const getFormattedCart = data => {
  let formattedCart = null

  if (undefined === data || !data?.cart?.contents?.nodes?.length) {
    return formattedCart
  }

  const givenProducts = data.cart.contents.nodes

  // Create an empty object.
  formattedCart = {}
  formattedCart.products = []
  let totalProductsCount = 0

  for (let i = 0; i < givenProducts.length; i++) {
    const givenProduct = givenProducts[i].product
    const product = {}
    const total = getFloatVal(givenProducts[i].total)

    product.productId = givenProduct?.node?.databaseId
    product.cartKey = givenProducts[i].key
    product.name = givenProduct?.node?.name
    product.qty = givenProducts[i].quantity
    product.price = total / product.qty
    product.totalPrice = givenProducts[i].total

    // Ensure we can add products without images to the cart
    !isEmpty(givenProduct?.node?.image)
      ? (product.image = {
          sourceUrl: givenProduct?.node?.image.sourceUrl,
          srcSet: givenProduct?.node?.image.srcSet,
          title: givenProduct?.node?.image.title,
        })
      : (product.image = {
          sourceUrl: withPrefix("/ico-product-placeholder.svg"),
          srcSet: withPrefix("/ico-product-placeholder.svg"),
          title: givenProduct?.node?.name,
        })

    totalProductsCount += givenProducts[i].quantity

    // Push each item into the products array.
    formattedCart.products.push(product)
  }

  formattedCart.totalProductsCount = totalProductsCount
  formattedCart.totalProductsPrice = data.cart.total
  formattedCart.totalSubProductsPrice = data.cart.subtotal

  return formattedCart
}

/**
 * Get the updated items in the below format required for mutation input.
 *
 * [
 * { "key": "33e75ff09dd601bbe6dd51039152189", "quantity": 1 },
 * { "key": "02e74f10e0327ad868d38f2b4fdd6f0", "quantity": 1 },
 * ]
 *
 * Creates an array in above format with the newQty (updated Qty ).
 *
 */
export const getUpdatedItems = (products, newQty, cartKey) => {
  // Create an empty array.
  const updatedItems = []

  // Loop through the product array.
  products.map(cartItem => {
    // If you find the cart key of the product user is trying to update, push the key and new qty.
    if (cartItem.cartKey === cartKey) {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: parseInt(newQty),
      })

      // Otherwise just push the existing qty without updating.
    } else {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: cartItem.qty,
      })
    }
    return null
  })

  // Return the updatedItems array with new Qtys.
  return updatedItems
}

export const createCheckoutData = (order, inpost) => {
  const checkoutData = {
    clientMutationId: v4(),
    billing: {
      firstName: order.firstName,
      lastName: order.lastName,
      address1: order.address1,
      address2: order.address2,
      postcode: order.postcode,
      city: order.city,
      country: order.country,
      email: order.email,
      phone: order.phone,
    },
    shipping: {
      company: order.companyName + " - NIP - " + order.companyNIP,
      address1: order.companyAddress1,
      address2: order.companyAddress2,
      postcode: order.companyPostcode,
      city: order.companyCity,
    },
    shipToDifferentAddress: order.invoice,
    customerNote: `${order.customerNote} ${
      inpost?.name ? `, Paczkomat: ${inpost.name}` : ""
    }`,
    paymentMethod: order.paymentMethod,
    shippingMethod: order.shippingMethod,
    transactionId: v4(),
    isPaid: false,
  }

  if (order.createAccount) {
    checkoutData.account = {
      username: order.username,
      password: order.password,
    }
  }

  return checkoutData
}

export const getPaymentMethod = id => {
  switch (id) {
    case "przelewy24":
      return "Przelewy24"
    case "bacs":
      return "Tradycyjny przelew bankowy"
    case "cod":
      return "Płatność za pobraniem"
    case "payinstore":
      return "Płatność w sklepie"
    default:
      return "-"
  }
}
